window.loadJs('/media/pa_bootstrap/leaflet/jquery-3.7.0.min.js', () => {
	function getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	}

	function setCookie(cname, cvalue, exdays) {
		const d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		let expires     = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function deleteCookie(name) {
		setCookie(name, '', -1);
	}

	let cookieName = WEBSITE_ID + '_contact_response';
	let cookie     = getCookie(cookieName);
	let data       = cookie ? JSON.parse(decodeURIComponent(cookie)) : false;

	$('form').on('submit', function () {
		$('html').css({
			transition : '.5s all',
			filter     : 'brightness(0.5) grayscale(1)',
			overflow   : 'hidden',
			transform  : 'scale(.95)',
			background : '#000',
		});
		$(this).find('[type="submit"]').prop('disabled', true);
	})

	if (data) {
		let $div     = $('.contact_response');
		let divClass = 'alert-warning';
		let message  = 'Errore generico';

		switch (data.msgStatus) {
			case 'error':
				divClass = 'alert-danger';
				message  = data.errors.join('<br>');
				break;
			case 'ok':
				divClass = 'alert-success';
				message  = data.message;
				break;
		}

		$div.removeClass('d-none').addClass(divClass).html(message);
		$('html, body').animate({scrollTop : $div.offset().top - 50}, 500);
		deleteCookie(cookieName, {path : '/'});
		if (typeof (data.errorfields) !== 'undefined') $.each(data.errorfields, function (k, v) {
			$(':input[name="' + v + '"]').parent().addClass('is-invalid');
		});
	}
});
