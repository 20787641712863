//let intmeg = setInterval(function () {
//	if ($('.Megamenu').hasClass('is-ready')) {
//		menufn();
//		clearInterval(intmeg);
//	}
//}, 500);

let intmeg = setInterval(function () {
	let megamenu = document.querySelector('.Megamenu');
	if (megamenu && megamenu.classList.contains('is-ready')) {
		menufn();
		clearInterval(intmeg);
	}
}, 500);

function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	let expires     = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//function menufn() {
//	$('#modalLogin').removeClass('d-none');
//	let loginName = getCookie('primiero_tn_it_user_log_in');
//	if (loginName) {
//		if (loginName.length > 50) loginName = 'Anonimo';
//		$('#login_link')
//				.off('click')
//				.attr('href', '/admo/auth/logout')
//				.removeAttr('aria-controls')
//				.text(loginName + ' (Logout)');
//	}
//	let loginError = getCookie('primiero_tn_it_login_error');
//	if (loginError) {
//		$('#login_link span').trigger('click');
//		//$('#form_modal_login').prepend('<div class="Alert Alert--error Alert--withIcon u-margin-r-bottom" role="alert">' + loginError + '</div>');
//		$('#form_modal_login').prepend('<div class="alert alert-danger" role="alert">' + loginError + '</div>');
//		setCookie('primiero_tn_it_login_error', '', -1);
//	}
//}

function menufn() {
	let modalLogin = document.getElementById('modalLogin');
	if (modalLogin) {
		modalLogin.classList.remove('d-none');
	}

	let loginName = getCookie('primiero_tn_it_user_log_in');
	if (loginName) {
		if (loginName.length > 50) {
			loginName = 'Anonimo';
		}

		let loginLink = document.getElementById('login_link');
		if (loginLink) {
			loginLink.removeEventListener('click', handleClick);
			loginLink.href = '/admo/auth/logout';
			loginLink.removeAttribute('aria-controls');
			loginLink.removeAttribute('data-bs-toggle');
			loginLink.removeAttribute('data-bs-target');
			loginLink.removeAttribute('data-focus-mouse');
			loginLink.textContent = loginName + ' (Logout)';
		}
	}

	let loginError = getCookie('primiero_tn_it_login_error');
	if (loginError) {
		let loginLinkSpan = document.querySelector('#login_link span');
		if (loginLinkSpan) {
			loginLinkSpan.click();
		}

		let formModalLogin = document.getElementById('form_modal_login');
		if (formModalLogin) {
			formModalLogin.insertAdjacentHTML('afterbegin', '<div class="alert alert-danger" role="alert">' + decodeURI(loginError).replace(/%2F/gi, '/') + '</div>');
		}

		setCookie('primiero_tn_it_login_error', '', -1);
	}
}

function handleClick(event) {
	event.preventDefault();
}

document.getElementById('reshow_cookie').addEventListener('click', e => {
	e.preventDefault();
	let $c = document.querySelector('.cookiebar');
	$c.classList.add('show');
	$c.style.display = '';

	document.cookie = 'cookies_consent=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
});